<template>
    <div>
        <PageHeader titleHeader="Contato" subtitleHeader="Entre em contato comigo e surpreenda-se" />
        <div class="row">
            <div class="col-md-12 body-on contato-panel">
                <p class="lead contato-cartao">
                    <strong>Dirce Bolos</strong>
                    <br> Rua das Rosas 12 - Trianon
                    <br> Guarapuava, PR 85012-080
                    <br>
                    <abbr>Telefone:</abbr> (42) 3623-3581
                    <br> Complemento: (Casa n&uacute;mero 12)
                </p>
                <div>
                    <div class="row">
                        <div class="col body-off"></div>
                        <div class="col-md-4 body-on contato-box">
                            <p class="lead ">
                                <strong>Mapa do endereço</strong>
                            </p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.193111825067!2d-51.456244100000006!3d-25.3983457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ef3620dbf8fa35%3A0x82f907b0fee55b90!2sR.+das+Rosas%2C+12+-+Trianon%2C+Guarapuava+-+PR%2C+85012-080!5e0!3m2!1spt-BR!2sbr!4v1444086465707"
                                width="400" height="300" frameborder="0" style="border:0"></iframe>
                        </div>
                        <div class="col-md-1 body-off"></div>
                        <div class="col-md-4 body-on contato-box">
                            <p class="lead">
                                <strong>Foto da rua</strong>
                            </p>
                            <iframe src="https://www.google.com/maps/embed?pb=!4v1523560476159!6m8!1m7!1sCOqVcMvabDlze6plV8qTdw!2m2!1d-25.3983787498596!2d-51.45619907949764!3f275.76402993968014!4f-7.815269659570447!5f1.4037254187696848"
                                width="400" height="300" frameborder="0" style="border:0"></iframe>

                        </div>
                        <div class="col body-off"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/layout/PageHeader";
export default {
  name: "Contato",
  components: {
    PageHeader
  }
};
</script>